import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Error, NotFoundImage } from '@openx/components/core';

export const PageNotFound = (): JSX.Element => {
  const history = useHistory();

  const redirectHome = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Error
      title="Page Not Found"
      titlePrefix="OpenX"
      subtitle="We've looked everywhere and we just can't find that page in our universe."
      actions={[
        {
          children: 'Take me home please',
          color: 'secondary',
          'data-test': 'button-back',
          onClick: redirectHome,
          variant: 'contained',
        },
      ]}
      PictureComponent={NotFoundImage}
      data-test="page-not-found"
    />
  );
};
