import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../components/AuthContext';
import type { InstanceListOption, Session } from '../types';

import {
  type FetchInstancesListResponse,
  fetchInstancesListResponse,
} from './fetchInstancesListResponse';
import {
  type FetchSessionInfoResponse,
  fetchSessionInfoResponse,
} from './fetchSessionInfoResponse';
import type { FetchSessionInfoError } from './types';
import { getOAuthParams } from './verifyOAuthParams';

type UseFetchSessionInfoReturnType = FetchSessionInfoResponse &
  FetchInstancesListResponse & {
    loading: boolean;
  };

export const useFetchSessionInfo = (): UseFetchSessionInfoReturnType => {
  const location = useLocation();
  const { currentUser } = useAuth();

  const [instancesList, setInstancesList] = useState<InstanceListOption[]>([]);
  const [registered, setRegistered] = useState<boolean>(false);
  const [session, setSession] = useState<Session | null>(null);
  const [error, setError] = useState<FetchSessionInfoError | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const fetchSession = async () => {
      try {
        const {
          registered: newRegistered,
          instancesList: newInstancesList,
          error: errorFetchInstancesList,
        } = await fetchInstancesListResponse({
          currentUser,
        });

        if (errorFetchInstancesList) {
          setError(errorFetchInstancesList);
          return;
        }
        if (newRegistered !== null) {
          setRegistered(newRegistered);
        }
        if (newInstancesList !== null) {
          setInstancesList(newInstancesList);
        }

        const { sessionId } = getOAuthParams(location);
        if (!sessionId) {
          return;
        }

        const { session: newSession, error } = await fetchSessionInfoResponse({
          currentUser,
          sessionId,
        });

        if (error) {
          setError(error);
          return;
        }

        if (newSession !== null) {
          setSession(newSession);
        }
      } catch (error) {
        const { message, name } = error;
        setError({ message, name, type: 'unknown' });
      } finally {
        setLoading(false);
      }
    };

    fetchSession();
    // this REALY need to be run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    instancesList,
    loading,
    registered,
    session,
  };
};
