import { yupResolver } from '@hookform/resolvers/yup';
import {
  parseActionCodeURL,
  ActionCodeOperation,
  ActionCodeURL,
} from 'firebase/auth';
import { useSnackbar } from 'notistack';
import { useCallback, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { FullLoader } from '@openx/components/core';

import { TIMEOUT_BEFORE_REDIRECT } from 'config';

import { useAuth } from '../components/AuthContext';
import { SsoLayout } from '../components/SsoLayout';

import {
  FormValues,
  SetPasswordForm,
  SetPasswordFormValues,
  validationSchema,
} from './SetPasswordForm';

const initialValues = {
  newPassword: '',
};

export function UserManagement(): JSX.Element | null {
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { verifyPasswordResetCode, confirmPasswordReset } = useAuth();

  const [actionCodeURL, setActionCodeURL] = useState<ActionCodeURL | null>(
    null,
  );
  const [userEmail, setUserEmail] = useState<string>('');

  const formMethods = useForm<FormValues>({
    defaultValues: initialValues,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const actionCodeURL = parseActionCodeURL(window.location.href);
        if (!actionCodeURL) {
          throw new Error('Malformed URL');
        }

        if (actionCodeURL.operation !== ActionCodeOperation.PASSWORD_RESET) {
          throw new Error('Unsupported operation');
        }

        setActionCodeURL(actionCodeURL);
        setUserEmail(await verifyPasswordResetCode(actionCodeURL.code));
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
        history.push(`/forgot-password${location.search}${location.hash}`);
      }
    };

    verifyCode();
  }, [
    enqueueSnackbar,
    verifyPasswordResetCode,
    setUserEmail,
    setActionCodeURL,
    location,
    history,
  ]);

  const handleSubmit = useCallback(
    async (values: SetPasswordFormValues) => {
      try {
        if (!actionCodeURL) {
          history.push(`/login${location.search}${location.hash}`);
          return;
        }
        const { newPassword } = values;
        const { code, continueUrl } = actionCodeURL;

        await confirmPasswordReset(code, newPassword);
        enqueueSnackbar('New password set successfully', {
          variant: 'success',
        });

        await new Promise(resolve =>
          setTimeout(resolve, TIMEOUT_BEFORE_REDIRECT),
        );

        if (!continueUrl) {
          history.push('/login');
          return;
        }

        window.location.href = continueUrl;
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, confirmPasswordReset, location, history, actionCodeURL],
  );

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!actionCodeURL || !userEmail) {
    return <FullLoader />;
  }

  return (
    <SsoLayout>
      <FormProvider {...formMethods}>
        <SetPasswordForm
          onSubmitHandler={formMethods.handleSubmit(handleSubmit)}
          onCancel={onCancel}
          userEmail={userEmail}
        />
      </FormProvider>
    </SsoLayout>
  );
}
