import { getEnvironmentConfigs } from 'environmentConfigs';
import { Route, RouteProps, Switch } from 'react-router-dom';

import { TIMEOUT_BEFORE_REDIRECT } from 'config';
import { Consent } from 'modules/auth/consent';
import { ForgotPassword } from 'modules/auth/forgotPassword';
import { Login } from 'modules/auth/login';
import { PageNotFound } from 'modules/auth/PageNotFound';
import { PostPasswordSetup } from 'modules/auth/postPasswordSetup';
import { UserManagement } from 'modules/auth/userManagement';

const routes: RouteProps[] = [
  {
    component: () => {
      setTimeout(() => {
        window.location.href = getEnvironmentConfigs().defaultRedirectURI;
      }, TIMEOUT_BEFORE_REDIRECT);
      return null;
    },
    exact: true,
    path: '/',
  },
  {
    component: Login,
    exact: true,
    path: '/login',
  },
  {
    component: ForgotPassword,
    exact: true,
    path: '/forgot-password',
  },
  {
    component: UserManagement,
    exact: true,
    path: '/user-management',
  },
  {
    component: Consent,
    exact: true,
    path: '/consent',
  },
  {
    component: PostPasswordSetup,
    exact: true,
    path: '/password-setup',
  },
  {
    component: PageNotFound,
    path: '*',
  },
];

export const Routes = () => (
  <Switch>
    {routes.map((route, i) => (
      <Route key={i} {...route} />
    ))}
  </Switch>
);
